<div class="row justify-content-center">
    <div class="col-lg-8">
        <h3>Contact</h3>
        <h5>Andorra Feskens - Ritueelbegeleider</h5>
        <p>Heeft u vragen of wilt u meer informatie? <br/>Neemt u dan vrijblijvend contact met mij op.</p>
        <p>U kunt mij bellen op 06-52551698 <br/>U kunt mailen naar info@andorrafeskens.nl <br/>Of een bericht sturen via het contactformulier.</p>
        <p>Ik ben aangesloten bij de <a href="https://www.lbvr.nl/" target="_blank">Landelijke Beroepsvereniging van Ritueelbegeleiders</a> & 
        <a href="https://www.dementievriendelijkeuitvaartzorg.nl/dementievriendelijke-ritueelbegeleiders/" target="_blank">Dementievriendelijke Uitvaartzorg.</a></p>
        <h5>Contactformulier</h5>
        <form>
            <div class="form-group">
                <label for="emailFormControl">E-mailadres*</label>
                <input type="email" class="form-control" id="emailFormControl" placeholder="Uw e-mailadres..." (input)="onEmailInputChanged($event.target.value)">
            </div>
            <div class="form-group">
                <label for="nameFormControl">Naam</label>
                <input class="form-control" id="nameFormControl" placeholder="Uw naam..." (input)="onNameInputChanged($event.target.value)">
            </div>
            <div class="form-group">
                <label for="messageFormControl">Bericht*</label>
                <textarea class="form-control" id="messageFormControl" rows="3" placeholder="Uw bericht..." (input)="onMessageInputChanged($event.target.value)"></textarea>
            </div>
            <button type="button" class="btn btn-light" (click)="sendContactForm()" [disabled]="isMessageBeingSend">Verzenden</button>
            <p class="mt-3" [ngClass]="{'invisible': !isMessageSend && !isMessageSendError, 'text-danger': isMessageSendError}"><i>{{messageSendText}}</i></p>
          </form>
    </div>
    <div class="col-auto">
        <img class="responsive-image" src="../../assets/images/logo.png" />
    </div>
</div>