<div class="row justify-content-center">
    <div class="col-lg-7 order-lg-2">
        <h3>Als een boom…</h3>
        <p>
            Je bent voor mij als een boom, met wortels diep in de aarde, een sterke stam <br/>en je kruin machtig en hoog.<br/>
            Je bent voor mij als een blad, groen en kalm ruisend in de wind.
        </p>
        <p>
            Ik weet: ik laat je los nu.<br/>Ik voel: je blijft bij mij, jouw herinnering is in mijn binnen gegroeid.<br/>
            Je bent voor mij als een boom, hebt wortels geschoten in mij.
        </p>
        <p>Voor altijd…</p>
    </div>
    <div class="col-lg-5 order-lg-1">
        <img class="responsive-image" src="../../assets/images/welcome-page-image.jpg" />
    </div>
</div>
<div class="row justify-content-center mt-5">
    <div class="col-lg-8">
        <h3>Ritueelbegeleiding</h3>
        <h5>Aandacht voor herinnering bij bijzondere momenten</h5>
        <p>De moeilijkste vorm van liefhebben is afscheid nemen van iemand waarvan je houdt.</p>
        <p>Een mooi afscheid is een kostbare herinnering… <br/>In de slechts enkele dagen die er zijn na het overlijden van een dierbare
            tot aan het afscheid wordt er veel gevraagd van de nabestaanden. In deze tijd vloeit menige traan, maar er zal ook (uitbundig) 
            gelachen worden bij allerlei mooie, gekke en betekenisvolle herinneringen. En juist die gebeurtenissen wilt u terug horen en zien 
            in een afscheidsviering. Niet alleen wat er allemaal gebeurd is in de laatste dagen / weken tot aan het overlijden.
        </p>
        <p>Het bijzondere herinneren. De herinneringen bijzonder maken.</p>
    </div>
    <div class="col-lg-4">
        <img class="responsive-image" src="../../assets/images/ritual-accompaniment-image.png" />
    </div>
</div>