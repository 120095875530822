import { Component, ContentChild, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import emailjs from 'emailjs-com';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit{

  public isMessageSend: boolean = false;
  public isMessageSendError: boolean = false;
  public isMessageBeingSend: boolean = false;

  public messageSendText: string;

  public email: string;
  public name: string;
  public message: string;

  constructor(private titleService: Title, private metaService: Meta) {  }

  ngOnInit(): void {
    this.titleService.setTitle("Andorra Feskens | Contact");
    this.metaService.addTags([
      {name: 'keywords', content: 'Ritueelbegeleider, ritueelbegeleiding, contact, telefoonnummer, email, formulier'},
      {name: 'description', content: 'U kunt mij bereiken via telefoon, mail of door het invullen van het contactformulier. Uiteraard volgt hierop zo snel mogelijk een reactie.'},
      {property: 'og:locale', content: 'nl_NL'},
      {property: 'og:description', content: 'U kunt mij bereiken via telefoon, mail of door het invullen van het contactformulier. Uiteraard volgt hierop zo snel mogelijk een reactie.'},
      {property: 'og:url', content: 'https://www.andorrafeskens.nl/contact'},
      {property: 'og:site_name', content: 'Andorra Feskens | Contact'}
    ]);
  }

  sendContactForm(){
    this.isMessageBeingSend = true;
    this.isMessageSendError = false;

    if(this.email === null || this.email === undefined || this.email?.trim() === "" || this.message === null || this.message === undefined || this.message?.trim() === "")
    {
      this.isMessageSendError = true;
      this.isMessageBeingSend = false;
      this.messageSendText = "Het e-mailadres en bericht zijn verplicht voor het verzenden van het contactformulier.";
    }
    else 
    {
      this.messageSendText = "";

      emailjs.send("service_vnm26ta", "template_efbdt8h", { email: this.email, message: this.message, name: this.name }, "user_sun09R546vSVMwqC3Gtwc")
          .then((response) => {
            this.isMessageSend = true;
            this.isMessageBeingSend = false;
            this.messageSendText = "Bedankt voor uw bericht!";
          }, (err) => {
            this.isMessageSendError = true;
            this.isMessageBeingSend = false;
            this.messageSendText = "Kon het bericht niet verwerken, neem contact op met info@andorrafeskens.nl of 06-52551698.";
          });
    }
  }

  onMessageInputChanged(newValue: string){
    this.message = newValue;
  }

  onNameInputChanged(newValue: string){
    this.name = newValue;
  }

  onEmailInputChanged(newValue: string){
    this.email = newValue;
  }
}
