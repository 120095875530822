import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-method',
  templateUrl: './method.component.html',
  styleUrls: ['./method.component.scss']
})
export class MethodComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Andorra Feskens | Werkwijze");
    this.metaService.addTags([
      {name: 'keywords', content: 'Ritueelbegeleiding, ritueelbegeleider, werkwijze, kennismakingsgesprek, teksten, schrijven, ritueel, muziek, familiegesprek, ceremonie, Oss, Uden, Veghel, Nijmegen, \'s-Hertogenbosch, Maas, Waal, Tiel, Zaltbommel'},
      {name: 'description', content: 'Op een gepast moment in de dagen tot aan het afscheid neem ik de tijd om te luisteren naar wat belangrijk is geweest in het leven van degene die er niet meer is. Waarom die persoon belangrijk voor u was en zal blijven in uw leven.'},
      {property: 'og:locale', content: 'nl_NL'},
      {property: 'og:description', content: 'Op een gepast moment in de dagen tot aan het afscheid neem ik de tijd om te luisteren naar wat belangrijk is geweest in het leven van degene die er niet meer is. Waarom die persoon belangrijk voor u was en zal blijven in uw leven.'},
      {property: 'og:url', content: 'https://www.andorrafeskens.nl/werkwijze'},
      {property: 'og:site_name', content: 'Andorra Feskens | Werkwijze'}
    ]);
  }

}
