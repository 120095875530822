import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-job',
  templateUrl: './my-job.component.html',
  styleUrls: ['./my-job.component.scss']
})
export class MyJobComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Andorra Feskens | Wat doe ik?");
    this.metaService.addTags([
      {name: 'keywords', content: 'Ritueelbegeleiding, ritueelbegeleider, herinnering, afscheid, ceremoniespreker, overlijden, symbolen, rituelen'},
      {name: 'description', content: 'Hartverwarmend luisteren, schrijven en spreken… Een ritueelbegeleider is vooral een luisteraar, om daarna te kunnen vertalen en te verwoorden wat naasten zelf geen woorden kunnen geven. Ik sta u bij, luister naar de verhalen, vertaal deze in tekst(en), en vertel ze (desgewenst) tijdens een herinneringsvol afscheid.'},
      {property: 'og:locale', content: 'nl_NL'},
      {property: 'og:description', content: 'Hartverwarmend luisteren, schrijven en spreken… Een ritueelbegeleider is vooral een luisteraar, om daarna te kunnen vertalen en te verwoorden wat naasten zelf geen woorden kunnen geven. Ik sta u bij, luister naar de verhalen, vertaal deze in tekst(en), en vertel ze (desgewenst) tijdens een herinneringsvol afscheid.'},
      {property: 'og:url', content: 'https://www.andorrafeskens.nl/wat-doe-ik'},
      {property: 'og:site_name', content: 'Andorra Feskens | Wat doe ik?'}
    ]);
  }

}
