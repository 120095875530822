import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Andorra Feskens | Tarief");
    this.metaService.addTags([
      {name: 'keywords', content: 'Ritueelbegeleider, ritueelbegeleiding, kosten, tarief, maatwerk, wens'},
      {name: 'description', content: 'Als ritueelbegeleider kan ik u helpen bij de invulling en presentatie van een passend afscheid. Natuurlijk is dat afhankelijk van uw behoefte en wensen.'},
      {property: 'og:locale', content: 'nl_NL'},
      {property: 'og:description', content: 'Als ritueelbegeleider kan ik u helpen bij de invulling en presentatie van een passend afscheid. Natuurlijk is dat afhankelijk van uw behoefte en wensen.'},
      {property: 'og:url', content: 'https://www.andorrafeskens.nl/tarief'},
      {property: 'og:site_name', content: 'Andorra Feskens | Tarief'}
    ]);
  }

}
