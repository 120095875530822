<div class="row justify-content-center">
    <div class="col-auto">
        <h3>Meer mogelijkheden</h3>
        <p>
            Meer mogelijkheden bij een overgang van wat was naar wat komt …
        </p>
        <p>
            Naast een afscheid zijn er ook nog tal van bijzondere (overgangs)momenten in het leven om bij stil te staan. Denk hierbij aan een huwelijk, 
            samenwonen / het aangaan van een samenlevingscontract, een scheiding, een verhuizing / emigratie, geboorte van een kind of de verwelkoming van 
            een adoptiekind, het vieren van een pensioen of jubileum, het openen of sluiten van een bedrijf. Maar ook de steenlegging / asverstrooiing, het 
            bijzetten van de urn, de verjaardag of sterfdag van de overledene. En hoe neem je betekenisvol afscheid van een collega of iemand die moet stoppen 
            met werken omdat zij / hij ernstig ziek is?
        </p>
        <p>
            Is er behoefte om stil te staan bij deze bijzondere momenten of verandering in levensfasen, dan ondersteun ik daarbij graag. Samen gaan we op zoek naar 
            betekenisvolle woorden, muziek, beeld en handelingen. Passend bij u, in het klein of in het groot, alleen of samen met anderen. We maken er een betekenisvol 
            en bijzonder moment van.
        </p>
        <p>
            De kosten van deze mogelijkheden zijn geheel afhankelijk van uw behoefte en wensen en zijn maatwerk. 
        </p>

    </div>
    <div class="row col-auto">
        <div class="col-4">
            <img class="responsive-image" src="../../assets/images/possibilities-image-1.jpg" />
        </div>
        <div class="col-4">
            <img class="responsive-image" src="../../assets/images/possibilities-image-2.jpg" />
        </div>
        <div class="col-4">
            <img class="responsive-image" src="../../assets/images/possibilities-image-3.jpg" />
        </div>
    </div>
    <div class="col-auto mt-5">
        <h5>Voor het overlijden …</h5>
        <p>
            Wilt u in een eerder stadium over het afscheid praten? Misschien over uw eigen afscheid? Ook dat kan. Het kan een hele geruststelling zijn als uw afscheids- 
            en uitvaartwensen al bekend zijn. Als u wilt, leggen we dat vast voor u en uw nabestaanden.
        </p>
        <p>
            Wilt u uw naderend afscheid alvast bespreken of wilt u vooraf met mij kennismaken? Dat kan! U kunt mij bellen, mailen of gebruik het contactformulier. 
            De gegevens vindt u op het tabblad contact. 
        </p>
        <p>
            Voor dit gesprek reken ik geen kosten. 
        </p>
    </div>
</div>