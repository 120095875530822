import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-possibilities',
  templateUrl: './possibilities.component.html',
  styleUrls: ['./possibilities.component.scss']
})
export class PossibilitiesComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Andorra Feskens | Meer mogelijkheden");
    this.metaService.addTags([
      {name: 'keywords', content: 'Ritueelbegeleider, ritueelbegeleiding, mogelijkheden, wensen, bijzonder, overlijden, rouw, afscheid, scheiding, huwelijk, geboorte, sterfdag, uitvaart'},
      {name: 'description', content: 'Meer mogelijkheden bij een overgang van wat was naar wat komt… Naast een afscheid zijn er ook nog tal van bijzondere (overgangs)momenten in het leven om bij stil te staan. Denk hierbij aan een huwelijk, samenwonen / het aangaan van een samenlevingscontract, een scheiding, een verhuizing / emigratie, geboorte van een kind of de verwelkoming van een adoptiekind, het vieren van een pensioen of jubileum, het openen of sluiten van een bedrijf. Maar ook de steenlegging / asverstrooiing, het bijzetten van de urn, de verjaardag of sterfdag van de overledene. En hoe neem je betekenisvol afscheid van een collega of iemand die moet stoppen met werken omdat zij / hij ernstig ziek is? Voor het overlijden… Wilt u uw naderend afscheid alvast bespreken of wilt u vooraf met mij kennismaken. Dat kan! Het kan een hele geruststelling zijn als uw afscheids- en uitvaartwensen al bekend zijn. Als u wilt, leggen we dat vast voor u en uw nabestaanden.'},
      {property: 'og:locale', content: 'nl_NL'},
      {property: 'og:description', content: 'Meer mogelijkheden bij een overgang van wat was naar wat komt… Naast een afscheid zijn er ook nog tal van bijzondere (overgangs)momenten in het leven om bij stil te staan. Denk hierbij aan een huwelijk, samenwonen / het aangaan van een samenlevingscontract, een scheiding, een verhuizing / emigratie, geboorte van een kind of de verwelkoming van een adoptiekind, het vieren van een pensioen of jubileum, het openen of sluiten van een bedrijf. Maar ook de steenlegging / asverstrooiing, het bijzetten van de urn, de verjaardag of sterfdag van de overledene. En hoe neem je betekenisvol afscheid van een collega of iemand die moet stoppen met werken omdat zij / hij ernstig ziek is? Voor het overlijden… Wilt u uw naderend afscheid alvast bespreken of wilt u vooraf met mij kennismaken. Dat kan! Het kan een hele geruststelling zijn als uw afscheids- en uitvaartwensen al bekend zijn. Als u wilt, leggen we dat vast voor u en uw nabestaanden.'},
      {property: 'og:url', content: 'https://www.andorrafeskens.nl/meer-mogelijkheden'},
      {property: 'og:site_name', content: 'Andorra Feskens | Meer mogelijkheden'}
    ]);
  }

}
