<div class="row justify-content-center">
    <div class="col-auto">
        <h3>Wat kost ritueelbegeleiding?</h3>
        <p>
            Als ritueelbegeleider kan ik u helpen bij een passend afscheid. Natuurlijk is dat afhankelijk van uw behoefte en wensen. 
            Voor de voorbereiding en het voorgaan van een ceremonie (zie onder werkwijze wat daar allemaal onder kan vallen) is het basistarief € 450,00.    
        </p>
        <p>
            Afhankelijk van eventuele andere wensen kan het zijn dat er extra kosten gemaakt worden. Denk hierbij aan extra gesprekken; live muzikale ondersteuning; 
            gebruik van ruimte anders dan crematorium of kerk; maken van een dia- of power-pointpresentatie; aanschaf spullen die gebruikt worden tijdens de 
            afscheidsceremonie; blijvend aandenken voor gasten etc. Hiervoor spreken we vooraf een apart tarief af. De reiskosten binnen een straal van 25 
            kilometer (enkele reis) vallen ook binnen het basistarief. Daarbuiten geldt een kilometervergoeding van € 0,19 / km.            
        </p>
        <h5>Losse onderdelen</h5>
        <p>
            Het is ook mogelijk om losse onderdelen door mij te laten verzorgen. Dit betreft maatwerk en de prijzen hiervoor worden in onderling overleg bepaald.
        </p>
        <ul>
            <li>Kennismakingsgesprek: kosteloos en vrijblijvend</li>
            <li>Herdenking op een later moment dan het afscheid</li>
            <li>In memoriam / levensverhaal schrijven</li>
            <li>Tekst voor het gedachtenisprentje / bidprentje schrijven</li>
            <li>Uitvoeren rituelen bij leven</li>
            <li>Afscheidsceremonie alleen op kerkhof of begraafplaats</li>
            <li>Kleine ceremonie rondom plaatsing van urn in een urnenmuur of uitstrooiing van de as</li>
            <li>Kleine ceremonie rondom plaatsing van de steen op het graf</li>
        </ul>
        <p><i>Prijzen zijn BTW vrij.</i></p>
        <p>
            Vergoeding ritueelbegeleiding vanuit uitvaartverzekering: nabestaanden kunnen de kosten van ritueelbegeleiding vergoed krijgen via de uitvaartverzekering. 
            Voor meer informatie zie het <a href="https://www.uitvaartverzekering.nl/onderwerpen/ritueelbegeleiding" target="_blank">kennisdossier.</a> 
            Hierin wordt uitgelegd wat de vergoedingen zijn voor ritueelbegeleiding en welke voorwaarden er gelden.
        </p>
    </div>
    <div class="col-auto">
        <img class="reduce-width-image responsive-image" src="../../assets/images/rate-image.jpg" />
    </div>
</div>