<div class="d-flex flex-column min-vh-100">
  <nav class="navbar navbar-expand-xl navbar-light pl-5 pr-5">
    <a class="navbar-brand" routerLink="ritueelbegeleider">
      <img class="mr-1" src="../assets/images/logo.png" width="30" height="30" class="d-inline-block align-top" alt="">
      Andorra Feskens
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li [ngClass]="{'nav-item active': navItem.isActive}" *ngFor="let navItem of navItems">
          <a class="nav-link" routerLink="{{navItem.link}}" routerLinkActive="active" (click)="navItemClicked(navItem)">{{navItem.text}}</a>
        </li>
      </ul>
    </div>
  </nav>
  <main class="container flex-fill">
    <router-outlet></router-outlet>
  </main>
  <footer class="footer-container">
    <div class="row justify-content-center">
        <div class="col-auto my-auto d-none d-sm-inline">
            <p><i>info@andorrafeskens.nl - 06-52551698 - <a href="../assets/documents/privacy_en_disclaimer_andorra_feskens.pdf" download>Privacy en disclaimer</a></i></p>
        </div>
        <div class="col-auto my-auto d-sm-none">
          <p>
            <i>info@andorrafeskens.nl<br/>06-52551698<br/><a href="../assets/documents/privacy_en_disclaimer_andorra_feskens.pdf" download>Privacy en disclaimer</a></i>
          </p>
      </div>
    </div>
  </footer>
</div>