import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Andorra Feskens | Wie ben ik?");
    this.metaService.addTags([
      {name: 'keywords', content: 'Ritueelbegeleiding, ritueelbegeleider, Andorra Feskens, rouw, babyuitvaarten, uitvaarteducatie, opleiding, scheiding, dementie, kinderen, familie'},
      {name: 'description', content: 'Mijn naam is Andorra Feskens. Ik ben geboren in 1966 in het pittoreske Alphen aan de Maas. Sinds mijn 18de jaar woon ik in Oss. Na vele jaren in bijzondere beroepen te hebben gewerkt, heb ik in 2019 besloten mijn hart te volgen. Ik heb bij Gaandeweg Uitvaarteducatie een opleiding gevolgd tot ritueelbegeleider en daarvoor ook mijn certificaat behaald. Hiermee kan ik me volledig toeleggen op het vormgeven van een afscheid of een ander bijzonder (overgangs)moment in het leven waarbij we het bijzondere herinneren en de herinneringen bijzonder maken.'},
      {property: 'og:locale', content: 'nl_NL'},
      {property: 'og:description', content: 'Mijn naam is Andorra Feskens. Ik ben geboren in 1966 in het pittoreske Alphen aan de Maas. Sinds mijn 18de jaar woon ik in Oss. Na vele jaren in bijzondere beroepen te hebben gewerkt, heb ik in 2019 besloten mijn hart te volgen. Ik heb bij Gaandeweg Uitvaarteducatie een opleiding gevolgd tot ritueelbegeleider en daarvoor ook mijn certificaat behaald. Hiermee kan ik me volledig toeleggen op het vormgeven van een afscheid of een ander bijzonder (overgangs)moment in het leven waarbij we het bijzondere herinneren en de herinneringen bijzonder maken.'},
      {property: 'og:url', content: 'https://www.andorrafeskens.nl/wie-ben-ik'},
      {property: 'og:site_name', content: 'Andorra Feskens | Wie ben ik?'}
    ]);
  }

}
