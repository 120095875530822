import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutMeComponent } from './about-me/about-me.component';
import { ContactComponent } from './contact/contact.component';
import { MethodComponent } from './method/method.component';
import { MyJobComponent } from './my-job/my-job.component';
import { PossibilitiesComponent } from './possibilities/possibilities.component';
import { RateComponent } from './rate/rate.component';
import { RitualAccompanimentComponent } from './ritual-accompaniment/ritual-accompaniment.component';

const routes: Routes = [
  { path: 'ritueelbegeleider', component: RitualAccompanimentComponent },
  { path: 'wat-doe-ik', component: MyJobComponent },
  { path: 'werkwijze', component: MethodComponent },
  { path: 'tarief', component: RateComponent },
  { path: 'wie-ben-ik', component: AboutMeComponent },
  { path: 'meer-mogelijkheden', component: PossibilitiesComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', redirectTo: 'ritueelbegeleider' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
