import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ritual-accompaniment',
  templateUrl: './ritual-accompaniment.component.html',
  styleUrls: ['./ritual-accompaniment.component.scss']
})
export class RitualAccompanimentComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Andorra Feskens | Ritueelbegeleider");
    this.metaService.addTags([
      {name: 'keywords', content: 'Ritueelbegeleider, ritueelbegeleiding, Oss, Den Bosch, Uden, Veghel, Nijmegen, \'S-Hertogenbosch, Maas, Waal, Tiel, Zaltbommel, begravenis, rouw, verwerken'},
      {name: 'description', content: 'Aandacht voor herinnering bij bijzondere momenten. De moeilijkste vorm van liefhebben is afscheid nemen van iemand waarvan je houdt. Een mooi afscheid is een kostbare herinnering… In dit afscheid kunnen groot en klein een rol hebben. Er is plaats voor verdriet, liefde en herinneringen. Van een leven samen naar een leven zonder samen.'},
      {property: 'og:locale', content: 'nl_NL'},
      {property: 'og:description', content: 'Aandacht voor herinnering bij bijzondere momenten. De moeilijkste vorm van liefhebben is afscheid nemen van iemand waarvan je houdt. Een mooi afscheid is een kostbare herinnering… In dit afscheid kunnen groot en klein een rol hebben. Er is plaats voor verdriet, liefde en herinneringen. Van een leven samen naar een leven zonder samen.'},
      {property: 'og:url', content: 'https://www.andorrafeskens.nl/ritueelbegeleider'},
      {property: 'og:site_name', content: 'Andorra Feskens | Ritueelbegeleider'}
    ]);
  }

}
