<div class="row justify-content-center">
    <div class="col-lg-8">
        <h3>Wie ben ik?</h3>
        <p>
            Mijn naam is Andorra Feskens. Ik ben geboren in 1966 in het pittoreske Alphen aan de Maas. Sinds mijn 18de jaar woon ik in Oss. 
            Ik ben getrouwd met Marc. Samen hebben wij drie kinderen, twee dochters (Lotte en Merel) die inmiddels uitwonend zijn en onze zoon Wessel 
            woont sinds hij stil geboren is in ons hart. 
        </p>
        <p>
            Mede door dit persoonlijke verlies heb ik ervaren hoe belangrijk het is om in de slechts korte tijd die er maar is herinneringen te maken voor 
            de toekomst en om het afscheid vorm te geven zodat het altijd gekoesterd wordt als een moment van liefde. Want een geliefde is nooit helemaal weg. 
            Als je iemand moet missen, dan is deze nog steeds bij je in herinneringen. 
        </p>
        <p>
            Ik weet dat er geen nieuwe herinneringen meer bij zullen komen, die tijd is geweest. Ik weet wel dat er nog vele momenten zullen zijn waarop hij gemist wordt. 
            Wat wij als gezin kunnen doen is de herinneringen die we hebben levend houden. Wij noemen zijn naam nog regelmatig en denken met veel liefde terug aan zijn afscheidsviering / begrafenis. 
        </p>
        <p>
            Na vele jaren in bijzondere beroepen te hebben gewerkt, heb ik in 2019 besloten mijn hart te volgen.
            Ik heb bij Gaandeweg Uitvaarteducatie een opleiding gevolgd tot ritueelbegeleider en daarvoor ook mijn certificaat behaald. Daarnaast volg ik regelmatig verdiepingsmodules zoals babyuitvaarten 
            en op rouwgebied, o.a. rouw bij dementie, rouw bij kinderen, rouw bij scheiding, rouw bij ontslag. 
            Hiermee kan ik me volledig toeleggen op het vormgeven van een afscheid of een ander bijzonder (overgangs)moment in het leven waarbij we het bijzondere 
            herinneren en de herinneringen bijzonder maken.
        </p>
        <p>
            Steeds vaker is bij een uitvaart een nabestaande met dementie betrokken. Een ritueelbegeleider met toegesneden kennis en ervaring maakt een groot verschil voor 
            de mens met dementie, voor de familie en voor de verzorgers. Daarom heb ik ook de training dementievriendelijke ritueelbegeleiding gevolgd.
        </p>
    </div>
    <div class="col-lg-4">
        <img class="w-75 responsive-image" src="../../assets/images/about-me-image.png" />
    </div>
</div>