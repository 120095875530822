<div class="row justify-content-center">
    <div class="col-auto">
        <h3>Wat doe ik?</h3>
        <p>
            Voor veel zaken kunt u terecht bij de uitvaartondernemer. Maar wie helpt u bij de voorbereiding van de afscheidsviering? Als ritueelbegeleider 
            (ook wel ceremoniespreker, spreker bij uitvaarten genoemd) kan ik veel voor u betekenen, zowel bij de voorbereiding als tijdens de ceremonie zelf. 
            Samen geven we vorm en inhoud aan een unieke viering. In zeer nauwe samenwerking met de uitvaartleider wordt met elkaar gewerkt aan het mooist mogelijke 
            afscheid voor degene die overleden is. In dit afscheid kunnen groot en klein een rol hebben. Er is plaats voor verdriet, liefde en herinneringen. Van een leven samen 
            naar een leven zonder samen. 
        </p>
        <p>
            Hartverwarmend luisteren, schrijven en spreken … <br/>Een ritueelbegeleider is vooral een luisteraar, om daarna te kunnen vertalen en te verwoorden wat naasten 
            zelf geen woorden kunnen geven. Ik sta u bij, luister naar de verhalen, vertaal deze in tekst(en), en vertel ze (desgewenst) tijdens een herinneringsvol 
            afscheid. Daarnaast kijken we naar bijpassende muziek, beelden, symbolen en rituelen. Het gebruik van symbolen, rituelen, foto’s en persoonlijke voorwerpen 
            van de overledene, wordt door de nabestaanden vaak als belangrijk ervaren. Het biedt houvast en troost. 
        </p>
        <p>
            Een ritueelbegeleider is niet gebonden aan een bepaalde levensbeschouwing, ik sta open en heb respect voor ieders levenswijze en levensbeschouwing.
        </p>
    </div>
    <div class="col-auto">
        <img class="reduce-width-image responsive-image" src="../../assets/images/my-job-image.jpg" />
    </div>
</div>