<div class="row justify-content-center">
    <div class="col-auto">
        <h3>Werkwijze & werkgebied</h3>
        <h5>Werkwijze</h5>
        <p>
            Op een gepast moment in de dagen tot aan het afscheid neem ik de tijd om te luisteren naar wat belangrijk is geweest in het leven van degene 
            die er niet meer is. Waarom die persoon belangrijk voor u was en zal blijven in uw leven. 
        </p>
        <p>Wat kunt u van mij verwachten?</p>
        <ul>
            <li>Een kennismakingsgesprek (gratis en vrijblijvend), waarin ik u informeer over de eventuele mogelijkheden</li>
            <li>Een interview / familiegesprek (circa 2 uur): samen zoekend naar het levensthema en / of een persoonlijk symbool dat als een rode draad gebruikt kan worden bij een afscheid</li>
            <li>Het schrijven van de teksten (welkomstwoord, levensverhaal / in memoriam, slotwoord) en het aanleveren van overige teksten / gedicht(en)</li>
            <li>Het meedenken in muziekkeuze of over de locatie waar het afscheid zal plaatvinden</li>
            <li>Het uitwerken van een ritueel</li>
            <li>Aandacht voor de verschillende doelgroepen ((klein)kinderen, verstandelijk beperkten, mensen met dementie)</li>
            <li>Ik lever de teksten aan bij de afgesproken contactpersoon, die kijkt of dit is zoals het gewenst is</li>
            <li>Eventuele correctie van de tekst (en indien nodig kan deze opnieuw beoordeeld worden)</li>
            <li>Voorgaan in de ceremonie en eventueel het uitspreken van teksten namens nabestaanden als zij dit zelf niet willen of kunnen</li>
            <li>Bewaarboekje met alle teksten na afloop</li>
            <li>Indien gewenst een nazorg gesprek </li>
        </ul>
        <h5>Mijn werkgebied</h5>
        <p>Omgeving Oss, Uden / Veghel, Nijmegen, ’s-Hertogenbosch, Maas en Waal, Tiel, Zaltbommel en alle tussenliggende plaatsen </p>
    </div>
    <div class="col-auto">
        <img class="responsive-image" src="../../assets/images/method-image.png" />
    </div>
</div>