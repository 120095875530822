import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavItem } from 'src/models/nav-item';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  navItems: NavItem[] = [
    {
      text: "Ritueelbegeleiding",
      isActive: false,
      link: "ritueelbegeleider"
    },
    {
      text: "Wat doe ik?",
      isActive: false,
      link: "wat-doe-ik"
    },
    {
      text: "Werkwijze",
      isActive: false,
      link: "werkwijze"
    },
    {
      text: "Tarief",
      isActive: false,
      link: "tarief"
    },
    {
      text: "Wie ben ik?",
      isActive: false,
      link: "wie-ben-ik"
    },
    {
      text: "Meer mogelijkheden",
      isActive: false,
      link: "meer-mogelijkheden"
    },
    {
      text: "Contact",
      isActive: false,
      link: "contact"
    }
  ];

  constructor(private _router: Router) {
    this._router.events.subscribe((navigation) => {
      if(navigation instanceof NavigationEnd){
        this.navItems.find(n => n.isActive).isActive = false;
        this.navItems.find(n => navigation.url.includes(n.link)).isActive = true;
      }
    });
  }
}
