import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RitualAccompanimentComponent } from './ritual-accompaniment/ritual-accompaniment.component';
import { MethodComponent } from './method/method.component';
import { RateComponent } from './rate/rate.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { PossibilitiesComponent } from './possibilities/possibilities.component';
import { ContactComponent } from './contact/contact.component';
import { MyJobComponent } from './my-job/my-job.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    RitualAccompanimentComponent,
    MethodComponent,
    RateComponent,
    AboutMeComponent,
    PossibilitiesComponent,
    ContactComponent,
    MyJobComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
